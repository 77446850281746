<app-header-app xLink="/signup">
  <div class="flex gdpr-box">
    <payee-icon name="gdpr"></payee-icon>
  </div>
</app-header-app>
<app-form-template title="Sajnos ez a token már lejárt.">
  <div class="description">
    <span>A regisztrációhoz kapcsolódó token biztonsági okokból lejárt, így egy új</span>
    <strong> megerősítő emailt kell kérned. </strong>
    <span>Kattints az alábbi</span>
    <strong> Újraküldés </strong>
    <span>gombra és nézd meg az email fiókodat.</span>
  </div>
  <payee-horizontal-line></payee-horizontal-line>
  <div class="flex resend-link-wrapper">
    <span color="grey-dark">Lejárt a korábbi tokened?</span>&nbsp;
    <a color="blue-payee" (click)="resendVerifyEmail()">Újraküldés</a>
  </div>
</app-form-template>