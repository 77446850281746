import {
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import copyToClip from 'clipboard-copy';
import moment from 'moment';

import { ErrorSnackbarComponent } from 'src/app/snackbars/error-snackbar/error-snackbar.component';
import { ApiKey, ApiService, GetApiKeysResponse } from 'src/app/services/api/api.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteKeyDialogComponent } from '../../../../dialogs/api-keys/delete-key-dialog/delete-key-dialog.component';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-api-integrations',
  templateUrl: './api-integrations.component.html',
  styleUrls: ['./api-integrations.component.scss']
})
export class ApiIntegrationsComponent implements OnInit, OnDestroy {
  apiKeys: ApiKey[] = [];
  loading = false;
  clientSubscription: Subscription;

  constructor(
    private snackBar: MatSnackBar,
    private apiService: ApiService,
    private dialog: MatDialog,
    private clientsService: ClientsService,
  ) {
  }

  async ngOnInit() {
    this.clientSubscription = this.clientsService.selectedClientSubject
      .subscribe(async () => {
        await this.loadAllKeys();
      });

    await this.loadAllKeys();
  }

  ngOnDestroy(): void {
    this.clientSubscription.unsubscribe();
  }

  async generateAPIKey() {
    try {
      this.loading = true;
      const result = await this.apiService.generateApiKey();
      this.apiKeys.push(result.apikey);
    } catch (error) {
      this.snackBar.openFromComponent(ErrorSnackbarComponent, {
        data: {
          message: 'Valami hiba történt az api kulcs generálásakor.',
          action: 'Ok',
        }
      });
    } finally {
      this.loading = false;
    }
  }

  async deleteAPIKey(key: string) {
    const dialogRef = this.dialog.open(DeleteKeyDialogComponent, {
      autoFocus: false,
      position: {
        top: '50px',
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }

      try {
        this.loading = true;
        await this.apiService.deleteApiKey(key);
        this.apiKeys = this.apiKeys.filter(apiKey => apiKey.value !== key);
      } catch (error) {
        this.snackBar.openFromComponent(ErrorSnackbarComponent, {
          data: {
            message: 'Valami hiba történt az api kulcs törlésekor.',
            action: 'Ok',
          }
        });
      } finally {
        this.loading = false;
      }
    });
  }

  copyToClipboard(keyText: string) {
    copyToClip(keyText).then(() => this.snackBar.openFromComponent(ErrorSnackbarComponent, {
      data: {
        message: 'Api kulcs vágólapra másolva.',
        action: 'Ok',
        type: 'success'
      }
    }));
  }

  private async loadAllKeys() {
    try {
      this.loading = true;
      const result = await this.apiService.getApiKeys();
      this.apiKeys = result.apikeys;
    } catch (err) {
      console.log(err);
    } finally {
      this.loading = false;
    }
  }
}
