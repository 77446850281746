import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-signup-finalization',
  templateUrl: './signup-finalization.component.html',
  styleUrls: ['./signup-finalization.component.scss']
})
export class SignupFinalizationComponent {
  readonly registerForm: FormGroup;

  loading = false;

  private readonly token: string;
  readonly email: string;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Regisztráció - Payee');
    this.registerForm = this.fb.group({
      lastName: ['', Validators.required],
      firstName: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      newsletter: false,
    });

    this.token = this.route.snapshot.queryParams.token;
    this.email = this.route.snapshot.queryParams.email;

    if (!this.token || !this.email) {
      console.log('Token or email missing', {
        email: this.email,
        token: this.token,
      });
      this.router.navigateByUrl('/signup');
    }
  }

  get lastName() { return this.registerForm.get('lastName'); }
  get firstName() { return this.registerForm.get('firstName'); }
  get password() { return this.registerForm.get('password'); }
  get newsletter() { return this.registerForm.get('newsletter'); }

  async finalizeSignup() {
    if (this.registerForm.invalid || this.loading) {
      Object.values(this.registerForm.controls).forEach(control => control.markAsDirty());

      return;
    }

    try {
      this.loading = true;
      await this.authService.finalizeSignup({
        name: `${this.lastName.value} ${this.firstName.value}`,
        password: this.password.value,
        is_newsletter_enabled: this.newsletter.value,
        token: this.token,
      });
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt', null, {
        duration: 15000,
      });
    } finally {
      this.loading = false;
    }
  }
}
