<payee-popup>
  <ng-template #payeePopupTitleTemplate>
    <div class="title">
      Hoppá, úgy tűnik nincsen adószámod
    </div>
  </ng-template>

  <ng-template #payeePopupDescriptionTemplate>
    <div class="description">
      <span color="grey-medium">Adószám nélkül kizárólag a meglévő ügyeidet tudod
        kezelni, új ügyet nem tudsz indítani. Amennyiben
        rendelkezel adószámmal, kérlek vedd fel a kapcsolatot
        velünk a</span>
      <a
        href="mailto: hello@payee.tech"
        color="blue-king"
      ><strong>&nbsp;hello@payee.tech&nbsp;</strong></a>
      <span color="grey-medium">email címen és segítünk a továbblépésben.</span>
    </div>
  </ng-template>

  <ng-template #payeePopupSubmitTemplate>
    <button
      class="payee-button"
      payee-button
      color="blue"
      width="wide"
      height="normal"
      (click)="submit()"
    >
      Rendben
    </button>
  </ng-template>

  <ng-template #payeePopupCancelTemplate></ng-template>
</payee-popup>