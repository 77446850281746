<div class="wrapper flex column" [class.close]="!open">
  <div class="logo-box flex hcenter vcenter">
    <img
      *ngIf="open"
      class="logo"
      src="assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg"
      width="100px"
      routerLink="cases"
    />
    <img
      *ngIf="!open"
      class="logo"
      src="assets/shared/global-images/payee-logos/v1/payee-webclip.svg"
      width="56.83px"
      routerLink="cases"
    />
  </div>

  <div class="create-case-button-container flex vcenter">
    <button payee-button [isRoundButton]="false" routerLink="/cases/wizard">
      <img
        src="/assets/shared/global-images/icons/plus-white.svg"
        width="14px"
        height="14px"
      />
      <span *ngIf="open">&nbsp;Ügyindítás</span>
    </button>
  </div>

  <div
    class="menu-button flex vcenter"
    routerLinkActive="active"
    routerLink="cases"
    [matTooltip]="open ? '' : 'Ügyeim'"
    matTooltipPosition="right"
    matTooltipClass="sidebar-tooltip"
  >
    <img src="assets/shared/global-images/icons/list.svg" />
    <span [class.close]="!open">Ügyeim</span>
  </div>

  <div class="grow3"></div>

  <div [class.close]="!open" class="payee-email">hello&#64;payee.tech</div>
  <div [class.close]="!open" class="version">
    Verziószám: <strong #appVersion>v{{ versionNumber }}</strong>
  </div>
  <button
    [class.close]="!open"
    mat-button
    class="toggle-button"
    (click)="toggleOpen()"
  >
    <img
      *ngIf="open"
      width="12px"
      src="assets/shared/global-images/icons/arrow-double-left-grey.svg"
    />
    <img
      *ngIf="!open"
      width="12px"
      src="assets/shared/global-images/icons/arrow-double-right-grey.svg"
    />
  </button>
</div>
