import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { ErrorSnackbarComponent } from 'src/app/snackbars/error-snackbar/error-snackbar.component';

@Component({
  selector: 'app-get-password-link',
  templateUrl: './get-password-link.component.html',
  styleUrls: ['./get-password-link.component.scss']
})
export class GetPasswordLinkComponent {
  readonly forgotPasswordForm: FormGroup;

  loading = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private titleService: Title,
    private snackBar: MatSnackBar,
    private router: Router,
  ) {
    this.titleService.setTitle('Új jelszó kérése - Payee');
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() { return this.forgotPasswordForm.get('email'); }

  readonly sendEmail = async (): Promise<void> => {
    if (this.forgotPasswordForm.invalid || this.loading) {
      return;
    }

    try {
      this.loading = true;

      await this.authService.sendPasswordResetEmail({
        email: this.email.value,
      });

      this.router.navigate(['/forgot-password-get-link-success'], {
        queryParams: {
          email: this.email.value,
        },
      });
    } finally {
      this.loading = false;
    }
  };
}
