<div class="footer-wrapper">
  <!--first row-->
  <div class="first-row">
    <div>
      <img
        src="assets/shared/global-images/payee-logos/v1/white-payee-white-dot.svg"
        width="100px"
        height="41px"
      />
    </div>
    <div class="first-row-second-column">
      <a
        href="https://payee.tech/about"
        target="_blank"
      >
        <payee-icon
          class="link-icon"
          [name]="'external-link-white'"
          height="20px"
        ></payee-icon>
        <span>Kik vagyunk mi?</span>
      </a>
      <a
        href="https://help.payee.tech/hu/articles/6583276-ugyvedi-partnereink"
        target="_blank"
      >
        <payee-icon
          class="link-icon"
          [name]="'external-link-white'"
          height="20px"
        ></payee-icon>
        <span>Ügyvédi partnerünk</span>
      </a>
      <a
        href="https://help.payee.tech/hu/articles/6228843-a-payee-mukodesenek-jogi-alapjai"
        target="_blank"
      >
        <payee-icon
          class="link-icon"
          [name]="'external-link-white'"
          height="20px"
        ></payee-icon>
        <span>Jogi háttér</span>
      </a>
      <a
        href="https://payee.tech/contact"
        target="_blank"
      >
        <payee-icon
          class="link-icon"
          [name]="'external-link-white'"
          height="20px"
        ></payee-icon>
        <span>Kapcsolat</span>
      </a>
      <div class="separator-line"></div>
      <img
        class="flag"
        src="assets/shared/global-images/flags/HU.svg"
      />
    </div>
  </div>

  <!--second row-->
  <div class="second-row">
    A küldetésünk, hogy a követeléskezelés könnyű és barátságos legyen, hogy a
    cégek tartósan növekedhessenek.
  </div>

  <!--third row-->
  <div class="third-row">
    <payee-icon
      [name]="'gdpr'"
      [height]="'40px'"
      [width]="'106px'"
    ></payee-icon>
    <payee-icon
      class="ssl-icon"
      [name]="'ssl'"
      [height]="'40px'"
      [width]="'93px'"
    ></payee-icon>
  </div>

  <!--fourth row-->
  <div class="fourth-row">
    <div class="fourth-row-first-column">
      © Copyright 2024 Legal Labs Kft. | 1054 Budapest, Honvéd utca 8. |
      Cégjegyzékszám: 01-09-382985
    </div>
    <div class="fourth-row-second-column">
      <a
        href="https://www.payee.tech/legal/terms-and-conditions"
        target="_blank"
        >ÁSZF</a
      >
      <a
        href="https://www.payee.tech/legal/privacy-policy"
        target="_blank"
        class="middle-link"
        >Adatkezelési tájékoztató</a
      >
      <a
        href="https://help.payee.tech/hu/articles/6228854-impresszum"
        target="_blank"
        >Impresszum</a
      >
    </div>
  </div>
</div>
