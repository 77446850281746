import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import axios from 'axios';

export type CompanySearchAutocompleteItem = {
  name: string,
  party_type_id: number,
  type: TypeEnum,
  tax_number: string,
  country: string,
  settlement: string,
  postcode: number,
  street: string,
};

export type CompanySearchDetailedItem = {
  basicData: {
    name: string | undefined;
    long_name: string | undefined;
    type: 'org' | 'ind';
    partyTypeId: string | null;
    registrationNumber: string | null;
  };
  representativeData: {
    representative: {
      name: string | null;
      type: string | null;
      tax_number: string | null;
      representative_data: any | null;
    };
    address: {
      country: string;
      settlement: string;
      postcode: string;
      street: string;
    } | null;
  };
  bankAccountData: {
    bank_name: string | undefined;
    account_number: string | undefined;
    iban: string | undefined;
    account_holder_name: string | undefined;
  };
  contactData: {
    type: string | undefined;
    value: string | null;
  };
  addressData: {
    country: string;
    settlement: string;
    postcode: string;
    street: string;
  };
};

enum TypeEnum {
  ORG = 'org',
  IND = 'ind'
}

@Injectable({
  providedIn: 'root'
})
export class CompanySearchService {
  // tslint:disable-next-line
  private _autocompleteSearchLoading = 0;
  get autocompleteSearchLoading() { return !!this._autocompleteSearchLoading; }

  // tslint:disable-next-line
  private _detailedSearchLoading = 0;
  get detailedSearchLoading() { return !!this._detailedSearchLoading; }

  async getCompanySearchAutocompleteItems(searchString: string): Promise<CompanySearchAutocompleteItem[]> {
    if (!searchString || searchString.length < 3) {
      return [];
    }

    try {
      ++this._autocompleteSearchLoading;
      const url = `${environment.baseUrl}/api/company-search/autocomplete?searchString=${encodeURIComponent(searchString)}`;
      const result = await axios.get<{ items: CompanySearchAutocompleteItem[]; }>(url);
      return result.data.items;
    } finally {
      --this._autocompleteSearchLoading;
    }
  }

  async detailedSearch(searchString: string): Promise<{ company: CompanySearchDetailedItem; } | null> {
    if (!searchString || searchString.length < 3) {
      return null;
    }

    try {
      ++this._autocompleteSearchLoading;
      const url = `${environment.baseUrl}/api/company-search/detailed?searchString=${encodeURIComponent(searchString)}`;
      const result = await axios.get<{ company: CompanySearchDetailedItem; }>(url);
      return result.data;
    } finally {
      --this._autocompleteSearchLoading;
    }
  }
}
