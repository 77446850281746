import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { PaymentStepService } from "../../services/payment-step.service";
import { CasesService } from 'src/app/services/cases/cases.service';
import {
  MoneyTransferDialogComponent
} from 'src/app/aa-new-form/dialogs/money-transfer-dialog/money-transfer-dialog.component';
import { Router } from '@angular/router';
import { environment } from '../../../../../../environments/environment';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
  styleUrls: ['./payment-method.component.scss']
})
export class PaymentMethodComponent {
  isCreditCardPayment = true;

  constructor(
    public paymentStepService: PaymentStepService,
    private casesService: CasesService,
    private dialog: MatDialog,
    private router: Router,
  ) {
  }

  creditCardPaymentSelected(isCreditCardPayment: boolean) {
    this.isCreditCardPayment = isCreditCardPayment;
  }

  async startPayment() {
    if (this.isCreditCardPayment) {
      await this.payWithCreditCard();
    } else {
      await this.payWithMoneyTransfer();
    }
  }

  async payWithCreditCard() {
    try {
      await this.casesService.startWithCardPayment(this.paymentStepService.caseUuid);
    } catch (error) {
      console.error(error, error.message, error.details);
    } finally {
      //this.formHelperService.setPageLoading(false);
    }
  }

  async payWithMoneyTransfer() {
    try {
      //  this.formHelperService.setPageLoading(true);
      await this.casesService.startWithTransfer(this.paymentStepService.caseUuid);
      this.casesService.caseOpenedGtmEvent(
        this.paymentStepService.paymentStepData.type,
        this.paymentStepService.paymentStepData.payee_id,
      );
      this.openCaseStartSuccessDialog();
    } catch (error) {
      console.error(error);
    } finally {
      // this.formHelperService.setPageLoading(false);
    }
  }

  openCaseStartSuccessDialog() {
    const dialogRef = this.dialog.open(MoneyTransferDialogComponent, {
    position: {
      top: '50px',
     },
     autoFocus: false,
    });

    dialogRef.afterClosed().subscribe(async () => {
      await this.router.navigate(['/user/cases'], {
        queryParams: {
          filterType: 'payeeId',
          filter: this.paymentStepService.paymentStepData.payee_id,
        },
      });
    });
  }

  readonly environment = environment;
}
