import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-verify-token-expired',
  templateUrl: './verify-token-expired.component.html',
  styleUrls: ['./verify-token-expired.component.scss']
})
export class VerifyTokenExpiredComponent {
  resendPressed = false;

  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar,
  ) {
  }

  async resendVerifyEmail() {
    if (this.resendPressed) {
      return;
    }
    try {
      this.resendPressed = true;
      await this.authService.resendVerifyEmail();
      this.snackbar.open('Megerősítő email elküldve!', null, {
        duration: 15000,
      });
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt!', null, {
        duration: 15000,
      });
    } finally {
      setTimeout(() => this.resendPressed = false, 15000);
    }
  }
}
