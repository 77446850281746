<app-header-empty></app-header-empty>
<app-form-template title="Kérjük, erősítsd meg az email címedet!">
  <div class="description">
      Várunk a Payee-zők között! Kérjük, erősítsd meg az email címedet az
      emailben lévő gombra kattintva.
  </div>

  <payee-horizontal-line></payee-horizontal-line>

  <div class="flex vcenter hcenter">
    <span color="grey-dark">Nem érkezett meg az email?</span>&nbsp;
    <a class="resend-link" [attr.color]="!resendPressed ? 'blue-payee' : 'grey-medium'" (click)="resendVerifyEmail()">
      Újraküldés
    </a>
    <ng-container *ngIf="loading">
      &nbsp;
      <mat-progress-spinner color="primary" mode="indeterminate" diameter="16">
      </mat-progress-spinner>
    </ng-container>
  </div>
</app-form-template>
