<div class="text">
  A hangnem a kiküldött felszólításokra lesz hatással. Itt azt kell
  kiválasztanod, hogy Te mint hitelező, milyen kommunikációs stílusban szeretnéd
  felszólítani azt, aki tartozik Neked.
</div>
<div class="alt-theme">
  <mat-radio-group color="primary" [formControl]="toneOfVoice">
    <mat-radio-button [value]="informal">
      <span>Barátságos hangnem</span>
      <img
        src="assets/shared/global-images/icons/question-mark.svg"
        width="20px"
        height="20px"
        matTooltipPosition="right"
        matTooltipClass="custom-tooltip"
        matTooltip="
        Kedves, tegeződő, inkább
        figyelmeztető, mint felszólító
        hangnem."
      />
    </mat-radio-button>
    <mat-radio-button [value]="formal">
      <span>Hivatalos hangnem</span>
      <img
        src="assets/shared/global-images/icons/question-mark.svg"
        width="20px"
        height="20px"
        matTooltipPosition="right"
        matTooltipClass="custom-tooltip"
        matTooltip="
        Keményebb, magázódó hangnem, ahol
        már határozottan felszólítjuk az adóst,
        hogy amennyiben nem fizet, a jogi
        eljárás haladéktalanul elindul."
      />
    </mat-radio-button>
  </mat-radio-group>
</div>

<div class="save-icon flex vcenter" [class.show]="saveCount > 0">
  <img src="assets/shared/global-images/icons/dot-green.svg">
  <span>AUTOMATIKUSAN MENTVE</span>
</div>
