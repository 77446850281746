<app-login-template title="Üdv újra itt!">
  <div>
    <div class="input-label bottom-margin-10" color="grey-dark">
      Vagy lépj be ezzel:
    </div>
    <a routerLink="/sso/szamlazzhu">
      <button
        type="button"
        payee-button
        width="wide"
        color="white"
        [isBorderButton]="true"
      >
        Számlázz.hu
      </button>
    </a>
  </div>

  <payee-horizontal-line></payee-horizontal-line>

  <div>
    <span class="input-label" color="grey-dark">Még nem regisztráltál?</span
    >&nbsp;
    <a color="blue-payee" routerLink="/signup">Regisztrálj most</a>
  </div>

  <div>
    <span class="input-label" color="grey-dark">Elfelejtetted a jelszavad?</span
    >&nbsp;
    <a color="blue-payee" routerLink="/forgot-password-get-link">
      Új jelszó kérése
    </a>
  </div>
</app-login-template>
