import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CasesService, GetCardPaymentStatus, PaymentStatus } from 'src/app/services/cases/cases.service';
import { PaymentStepData } from '../payment-step/models/payment-step-data';

@Component({
  selector: 'app-payment-redirect-step',
  templateUrl: './payment-redirect-step.component.html',
  styleUrls: ['./payment-redirect-step.component.scss']
})
export class PaymentRedirectStepComponent implements OnInit {
  cardPaymentStatus?: GetCardPaymentStatus;

  private retryCount = 10;
  private retryInterval: NodeJS.Timeout;

  private readonly caseId: string;
  private readonly paymentId: string;
  private case: PaymentStepData;
  private paymentInProgressStatuses: PaymentStatus[] = [
    'Started',
    'InProgress',
    'Waiting',
    'Reserved',
    'Authorized',
  ];

  constructor(
    private casesService: CasesService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.caseId = this.route.snapshot.params.uuid;
    this.paymentId = this.route.snapshot.queryParams.paymentId;
  }

  async ngOnInit() {
    if (!this.paymentId) {
      await this.router.navigateByUrl(`case/${this.caseId}/payment`);
      return;
    }

    try {
      const data: any = await this.casesService.getCaseForm(this.caseId, 'payment');
      this.case = data.payment_form_data;
    } catch (error) {
      if (error?.response?.data?.payee_case_reference_id) {
        await this.router.navigate(['/user/cases'], {
          queryParams: {
            filter: error?.response?.data?.payee_case_reference_id,
            filterType: 'payeeId',
          },
        });
        return;
      }
      console.error('Error while loading case during payment redirect', error);
      await this.router.navigateByUrl('user/cases');
      return;
    }

    this.retryInterval = setInterval(async () => {
      try {
        this.cardPaymentStatus = await this.casesService.getCardPaymentStatus(this.paymentId, this.caseId);
      } catch (error) {
        console.error('Error while getting payment status', error);
      }

      if (!!this.cardPaymentStatus) {
        if (this.cardPaymentStatus.status === 'Succeeded') {
          this.casesService.caseOpenedGtmEvent(
            this.case.type,
            this.case.payee_id,
          );
          await this.router.navigate(['/user/cases'], {
            queryParams: {
              filter: this.case.payee_id,
              filterType: 'payeeId',
            },
          });
          clearInterval(this.retryInterval);
          return;
        } else if (!this.paymentInProgressStatuses.includes(this.cardPaymentStatus.status)) {
          await this.router.navigateByUrl(`case/${this.caseId}/payment`);
          clearInterval(this.retryInterval);
          return;
        }
      }

      --this.retryCount;
      console.log(this.retryCount);
      if (this.retryCount <= 0) {
        clearInterval(this.retryInterval);
        await this.router.navigateByUrl(`case/${this.caseId}/payment`);
      }
    }, 1000);
  }
}
