<div class="top-margin-20 information-container-blue">
  <!--fees-->
  <ng-container *ngFor="let fees of summaryStepService.summaryStepData.fees | keyvalue">
    <div *ngIf="fees.value.length > 0"  class="summary-item top-margin-10">
      <div class="summary-item-title">{{summaryStepService.casePriceTitle[fees.key].label}}</div>
      <div class="summary-item-title">{{summaryStepService.casePriceTitle[fees.key].amount}}</div>
    </div>
    <ng-container *ngFor="let fee of fees.value">
      <div class="summary-item top-margin-10">
        <div class="grey-text">
          {{ fee.label }}
        </div>
        <div>
          <span class="payee-fee-light">{{ fee.details_text }}</span>
          <span class="grey-text">
            {{ fee.amount | number : "1.0" : "hu" }} HUF</span
          >
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
