<app-header-empty></app-header-empty>
<app-form-template title="Regisztrálj ingyen" [formGroup]="registerForm" [submit]="register">
  <div class="form-content flex column">
    <payee-form-field width="wide">
      <span payee-label>Először is add meg az email címedet</span>
      <input payee-input formControlName="email">
      <ng-container payee-error *ngIf="email.dirty">
        <mat-error *ngIf="email.hasError('email')">Létező email címet adj meg</mat-error>
        <mat-error *ngIf="email.hasError('required')">A mező kitöltése kötelező</mat-error>
        <mat-error *ngIf="email.hasError('used')">
          Ezzel az email címmel már regisztráltak. Válassz másikat.
        </mat-error>
      </ng-container>
    </payee-form-field>

    <app-custom-checkbox formControlName="ppAndAszf">
      <div class="input-label-small">
        <span color="black">Elolvastam és elfogadom az </span>
        <a href="https://www.payee.tech/legal/terms-and-conditions" target="_blank" color="black">
          <strong><u>ÁSZF</u></strong>
        </a>
        <span color="black">-et és az </span>
        <a href="https://www.payee.tech/legal/privacy-policy" target="_blank" color="black">
          <strong><u>Adatkezelési tájékoztatót.</u></strong>&nbsp;
        </a>
      </div>
    </app-custom-checkbox>

    <mat-error *ngIf="ppAndAszf.dirty && ppAndAszf.hasError('required')">
      Kötelező elfogadni az ÁSZF-et és az Adatkezelési tájékoztatót!
    </mat-error>

    <mat-error *ngIf="registerForm.hasError('unknown')">
      {{ registerForm.getError('unknown') }}
    </mat-error>

    <button
      payee-button
      width="wide"
      height="tall"
      [disabled]="loading"
    >
      <div *ngIf="!loading" class="flex vcenter hcenter">
        <span>Tovább</span>
        <payee-icon name="arrow-right-white" [height]="'16px'"></payee-icon>
      </div>
      <div class="bw-theme flex hcenter" *ngIf="loading">
        <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
        </mat-progress-spinner>
      </div>
    </button>

    <div class="flex vcenter signup-separator">
      <payee-horizontal-line></payee-horizontal-line>
      <div class="input-label" color="grey-medium" style="align-self: auto; font-size: 14px;">VAGY</div>
      <payee-horizontal-line></payee-horizontal-line>
    </div>

    <a routerLink="/sso/szamlazzhu">
      <button type="button" payee-button width="wide" color="white" [isBorderButton]="true">Regisztrálj Számlázz.hu fiókoddal</button>
    </a>

    <payee-horizontal-line></payee-horizontal-line>

    <div class="flex hcenter">
      <span class="input-label" color="grey-dark">Már regisztráltál?</span>&nbsp;
      <a color="blue-payee" routerLink="/login">Jelentkezz be</a>
    </div>
  </div>
</app-form-template>
