import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { MatDialogModule } from "@angular/material/dialog";
import { StartBulkCaseSuccessDialogComponent } from "./start-bulk-case-success-dialog.component";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
  declarations: [StartBulkCaseSuccessDialogComponent],
  imports: [
    MatDialogModule,
    MatButtonModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class StartBulkCaseSuccessDialogModule { }