import {
  Component,
  HostBinding,
  ViewChild
} from '@angular/core';
import { ElementRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import packageJSON from 'src/../package.json';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent {
  open = true;
  @ViewChild('appVersion') el: ElementRef;
  @HostBinding('class.close') get close() { return !this.open; }

  loading = false;

  readonly environment = environment;

  get versionNumber() {
    return packageJSON.version;
  }

  toggleOpen() {
    this.open = !this.open;
  }
}
