<app-header-app xLink="/signup">
  <div class="flex gdpr-box">
    <payee-icon name="gdpr"></payee-icon>
  </div>
</app-header-app>
<app-form-template title="Regisztráció befejezése" [formGroup]="registerForm" (submit)="finalizeSignup()">
  <div class="input-label" color="black">
    A következő email címmel regisztrálsz:&nbsp;
    <strong>{{email}}</strong>
  </div>
  <payee-form-field width="wide">
    <span payee-label>Családneved</span> <!-- TODO: tooltip -->
    <input payee-input formControlName="lastName">
    <ng-container payee-error *ngIf="lastName.dirty">
      <mat-error *ngIf="lastName.hasError('required')">
        A mező kitöltése kötelező
      </mat-error>
    </ng-container>
  </payee-form-field>

  <payee-form-field width="wide">
    <span payee-label>Keresztneved</span> <!-- TODO: tooltip -->
    <input payee-input formControlName="firstName">
    <ng-container payee-error *ngIf="firstName.dirty">
      <mat-error *ngIf="firstName.hasError('required')">
        A mező kitöltése kötelező
      </mat-error>
    </ng-container>
  </payee-form-field>

  <payee-password-form-field width="wide" formControlName="password">
    <span payee-label>Jelszavad</span>
    <ng-container payee-error *ngIf="password.dirty">
      <mat-error *ngIf="password.hasError('required')">
        A mező kitöltése kötelező
      </mat-error>
      <mat-error *ngIf="password.hasError('minlength')">
        A jelszónak legalább 6 karakter hosszúnak kell lennie!
      </mat-error>
    </ng-container>
  </payee-password-form-field>

  <app-custom-checkbox formControlName="newsletter">
    <div class="input-label-small" color="black">
      Szeretnék értesülni a legújabb fejlesztésekről <strong>(Ajánlott)</strong>
    </div>
  </app-custom-checkbox>

  <button
    payee-button
    width="wide"
    height="tall"
    [disabled]="loading"
  >
    <div *ngIf="!loading" class="flex vcenter hcenter">
      <span>Tovább a Payee fiókomba</span>
      <payee-icon name="arrow-right-white"></payee-icon>
    </div>
    <div class="bw-theme flex hcenter" *ngIf="loading">
      <mat-progress-spinner color="accent" mode="indeterminate" diameter="30">
      </mat-progress-spinner>
    </div>
  </button>
</app-form-template>
