import { Component, OnDestroy, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorSnackbarComponent } from 'src/app/snackbars/error-snackbar/error-snackbar.component';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Client } from 'src/types';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-client-options',
  templateUrl: './client-options.component.html',
  styleUrls: ['./client-options.component.scss']
})
export class UserOptionsComponent implements OnInit, OnDestroy {
  readonly formal = 'formal' as const;
  readonly informal = 'informal' as const;

  readonly toneOfVoice: FormControl;

  saveCount: number = 0;

  private readonly destroy = new Subject<void>();

  constructor(
    private clientsService: ClientsService,
    private snackBar: MatSnackBar,
    private fb: FormBuilder,
  ) {
    this.toneOfVoice = this.fb.control(this.informal, Validators.required);
  }

  ngOnInit(): void {
    this.clientsService.selectedClientSubject
      .pipe(takeUntil(this.destroy))
      // TODO: fix any
      .subscribe((client: any) => {
        if (!client) {
          return;
        }

        if (client.default_tone_of_voice) {
          this.toneOfVoice.setValue(client.default_tone_of_voice, { emitEvent: false });
        } else {
          this.toneOfVoice.setValue(this.informal);
        }
      });

    this.toneOfVoice.valueChanges
      .pipe(takeUntil(this.destroy), debounceTime(777))
      .subscribe(async change => {
        try {
          await this.clientsService.updateDefaultToneOfVoice(change);

          ++this.saveCount;
          setTimeout(() => --this.saveCount, 2500);
        } catch (error) {
          console.log(error);
          this.snackBar.openFromComponent(ErrorSnackbarComponent, {
            data: {
              message: 'Valami hiba történt mentés közben',
              action: 'Ok',
            },
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }
}
