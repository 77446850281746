import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { AuthService } from 'src/app/shared/services/auth/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent {
  resendPressed = false;
  loading = false;

  constructor(
    private authService: AuthService,
    private snackbar: MatSnackBar,
    private titleService: Title,
  ) {
    this.titleService.setTitle('Regisztráció - Payee');
  }

  xClicked = async () => {
    await this.authService.logout();
  };

  async resendVerifyEmail() {
    if (this.resendPressed || this.loading) {
      return;
    }

    try {
      this.resendPressed = true;
      this.loading = true;
      await this.authService.resendVerifyEmail();
      this.snackbar.open('Megerősítő email elküldve!', 'OK', {
        duration: 5000,
      });
    } catch (error) {
      console.error(error);
      this.snackbar.open('Valami hiba történt!', 'OK', {
        duration: 5000,
      });
    } finally {
      this.loading = false;
      setTimeout(() => this.resendPressed = false, 15000);
    }
  }
}
