<div class="wrapper flex column vcenter">
  <div class="title">Adatvédelem és ÁSZF</div>
  <div class="description">
    Kérlek, a Payee használatához fogadd el a felhasználási feltételeket!
    Az alábbi linkeken elolvashatod mindkét dokumentumot.
  </div>

  <form [formGroup]="formGroup">
    <app-custom-checkbox formControlName="pp">
      <span class="checkbox-text">
        Elolvastam és elfogadom az
        <strong>
          <a href="https://www.payee.tech/legal/privacy-policy" target="_blank">
            Adatkezelési tájékoztatót.
          </a>
        </strong>
      </span>
    </app-custom-checkbox>
    <app-custom-checkbox formControlName="aszf">
      <span class="checkbox-text">
        Elolvastam és elfogadom az
        <strong>
          <a href="https://www.payee.tech/legal/terms-and-conditions" target="_blank">
            ÁSZF
          </a>
        </strong>
        -et.
      </span>
    </app-custom-checkbox>
  </form>

  <button
    mat-button class="accept-button" [disabled]="formGroup.invalid"
    (click)="onClose()"
    [mat-dialog-close]="true"
  >
    Tovább a Payee felületére
  </button>
</div>
