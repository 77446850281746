<div class="wrapper flex column vcenter">
  <div class="img-box flex vcenter hcenter">
    <img src="assets/shared/global-images/icons/trash-grey.svg" height="71.43px">
  </div>
  <div class="title flex vcenter">Biztosan ki akarod törölni a kulcsot?</div>
  <div class="description flex vcenter">
    A kulcs véglegesen elveszik.
  </div>
  <button mat-button class="confirm-button" [mat-dialog-close]="true">
    Törlés
  </button>
  <button mat-button class="close-button" [mat-dialog-close]="false">Mégsem</button>
</div>
