<div class="wrapper flex column vcenter">
  <lottie-player
    src="/assets/5595-success-animation.json"
    background="transparent"
    speed="1"
    style="width: 178.63px; height: 178.63px"
    loop
    autoplay
  >
  </lottie-player>
  <div class="title flex vcenter">
    Sikeres&nbsp;<u>tömeges</u>&nbsp;indítás!
  </div>
  <div class="description flex grow1 vcenter">
    <span>
      A rendszerünk feldolgozza a feltöltött állomány és
      <strong>1 napon belül</strong><br />
      <strong>elindul a követeléskezelési folyamat az egész állományra.</strong>
      Az ügyeid<br />
      állását az Ügyeim oldalon tudod nyomonkövetni valós időben.
    </span>
  </div>
  <button
    mat-button
    [mat-dialog-close]="true"
    class="accept"
    class="confirm-button"
  >
    Rendben
  </button>
</div>
