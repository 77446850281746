import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})

export class ClientProfileComponent {
  tab = 'bank-account-edit';

  constructor(
    private titleService: Title,
  ) {
    this.titleService.setTitle('Profilom - Payee');
  }
}
