import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent {

  passwordForm: FormGroup;

  get oldPwd(): AbstractControl { return this.passwordForm.get('oldPwd'); }
  get newPassword(): FormGroup { return this.passwordForm.get('newPassword') as FormGroup; }
  get newPwd(): AbstractControl { return this.newPassword.get('newPwd'); }
  get newPwdConfirm(): AbstractControl { return this.newPassword.get('newPwdConfirm'); }

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private snackbar: MatSnackBar,
  ) {
    this.passwordForm = this.fb.group({
      oldPwd: ['', [Validators.required, Validators.minLength(6)]],
      newPassword: this.fb.group(
        {
          newPwd: ['', [Validators.required, Validators.minLength(6)]],
          newPwdConfirm: ['', [Validators.required, Validators.minLength(6)]],
        }, { validator: this.confirmPasswordMatch() }),
    }, { validator: this.oldAndNewPasswordMatch() });
  }

  async onClose(): Promise<void> {
    if (this.passwordForm.invalid) {
      return;
    }

    try {
      await this.authService.forceResetPassword({
        old_password: this.oldPwd.value,
        password: this.newPwd.value,
        password_confirmation: this.newPwdConfirm.value,
      });

      this.snackbar.open('Jelszó megváltoztatva', 'OK', {
        duration: 5000,
      });
      this.dialogRef.close();
    } catch (err) {
      console.error(err);
      const code: string = err.response?.data?.code;
      if (code === 'invalid-password') {
        this.snackbar.open('Rossz jelszó!', 'OK', {
          duration: 5000,
        });
      } else {
        this.snackbar.open('Valami hiba történt!', 'OK', {
          duration: 5000,
        });
      }
    }
  }

  private confirmPasswordMatch(): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
      const pwd1 = formGroup.controls.newPwd;
      const pwd2 = formGroup.controls.newPwdConfirm;

      if (pwd1.value !== pwd2.value) {
        pwd1.setErrors({ ...pwd1.errors, confirmPasswordMatch: true });
      } else {
        if (!pwd1.errors) { return; }

        if (Object.keys(pwd1.errors).length === 0) {
          pwd1.setErrors(null);
        } else {
          const { confirmPasswordMatch, ...errors } = pwd1.errors;
          pwd1.setErrors(errors);
        }
      }
    };
  }

  private oldAndNewPasswordMatch(): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup) => {
      const pwd1 = formGroup.controls.oldPwd;
      const pwd2 = (formGroup.controls.newPassword as FormGroup).controls.newPwd;

      if (pwd1.value === pwd2.value) {
        pwd2.setErrors({ ...pwd2.errors, oldAndNewPasswordMatch: true });
      } else {
        if (!pwd2.errors) { return; }

        if (Object.keys(pwd2.errors).length === 0) {
          pwd2.setErrors(null);
        } else {
          const { oldAndNewPasswordMatch, ...errors } = pwd2.errors;
          pwd2.setErrors(errors);
        }
      }
    };
  }

  readonly environment = environment;
}
