<div class="wrapper flex column vcenter">
  <div class="lottie-box flex vcenter">
    <lottie-player 
      src="/assets/5595-success-animation.json" 
      background="transparent" speed="1" autoplay style="transform: scale(0.65);">
    </lottie-player>
  </div>
  <div class="title">Sikeres jelszócsere!</div>
  <div class="description">A Payee fiókodhoz tartozó jelszavad sikeresen megváltozott.</div>
  <button mat-button class="accept" [mat-dialog-close]>Rendben</button>
</div>