<div class="wrapper flex column">
  <div *ngIf="loading">
    <mat-progress-spinner color="primary" mode="indeterminate" diameter="50">
    </mat-progress-spinner>
  </div>
  <ng-container *ngIf="!loading">
    <div class="title" style="padding-bottom: 0">API kulcsok kezelése</div>
    <mat-card-subtitle style="padding-left: 20px">
      Új kulcsok hozzáadása és meglévők törlése
    </mat-card-subtitle>
    <div *ngIf="!apiKeys.length" style="margin: auto">
      <div class="title">Nincsenek megjeleníthető kulcsok!</div>
      <button
        mat-button
        class="gen-button"
        (click)="generateAPIKey()"
        [disabled]="loading"
        style=""
      >
        <img
          src="assets/shared/global-images/icons/plus-white.svg"
          width="14px"
          height="14px"
        />
        <span>Hozd létre első API kulcsod</span>
      </button>
    </div>
    <div>
      <mat-card *ngFor="let apiKey of apiKeys" style="margin: 10px">
        <mat-card-title>
          {{ apiKey.value }}
        </mat-card-title>
        <div style="display: flex">
          <mat-card-actions>
            <div style="display: flex; justify-content: flex-end">
              <button
                mat-icon-button
                (click)="copyToClipboard(apiKey.value)"
                style="margin: 0 20px"
              >
                <mat-icon style="color: #d04a02">content_copy</mat-icon>
              </button>
              <button
                *ngIf="!apiKey.partner"
                mat-icon-button
                (click)="deleteAPIKey(apiKey.value)"
                style="margin: 0 20px"
              >
                <mat-icon style="color: #d04a02">delete_outline</mat-icon>
              </button>
            </div>
          </mat-card-actions>
          <div style="margin-left: auto">
            <mat-card-subtitle style="text-align: right">
              Létrehozva: {{ apiKey.created_at | date : "yyyy.MM.dd. HH:mm:ss" }}
            </mat-card-subtitle>
            <mat-card-subtitle style="text-align: right" *ngIf="apiKey.partner">
              Külső partner által létrehozott kulcs, nem törölhető
            </mat-card-subtitle>
          </div>
        </div>
      </mat-card>
    </div>
    <button
      *ngIf="apiKeys.length"
      mat-fab
      color="primary"
      style="background: #d04a02; margin-left: auto"
      aria-label="API kulcs generálása"
      (click)="generateAPIKey()"
      [disabled]="loading"
    >
      <mat-icon>add_circle</mat-icon>
    </button>
  </ng-container>
</div>
