<div class="wrapper flex column vcenter">
  <img
    src="assets/shared/global-images/icons/shield.svg"
    class="logo"
    height="71.43px"
    width="57.14px"
  />
  <div class="title">Jelszó megváltoztatása</div>
  <div class="description">
    Add meg régi és új jelszavadat és meg is vagyunk.
  </div>
  <form [formGroup]="passwordForm">
    <app-custom-input-field appearance="outline">
      <span custom-label [class.custom-label-top]="!!oldPwd.value"
        >Régi jelszavad</span
      >
      <input custom-input type="password" #oldpwd [formControl]="oldPwd" />
      <mat-error
        custom-error
        *ngIf="oldPwd.dirty && oldPwd.hasError('required')"
        >A mező kitöltése kötelező</mat-error
      >
      <mat-error
        custom-error
        *ngIf="
          oldPwd.dirty &&
          oldPwd.hasError('minlength') &&
          !oldPwd.hasError('required')
        "
        >A jelszó legalább 6 karakter legyen!</mat-error
      >
      <mat-error custom-error *ngIf="oldPwd.hasError('wrongPassword')"
        >Hibás jelszó</mat-error
      >
      <div custom-suffix *ngIf="oldPwd.valid">
        <payee-icon [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"></payee-icon>
      </div>
    </app-custom-input-field>

    <app-custom-input-field appearance="outline">
      <span custom-label [class.custom-label-top]="!!newPwd.value"
        >Új jelszavad</span
      >
      <input custom-input type="password" #newpwd [formControl]="newPwd" />
      <mat-error
        custom-error
        *ngIf="newPwd.dirty && newPwd.hasError('required')"
        >A mező kitöltése kötelező</mat-error
      >
      <mat-error
        custom-error
        *ngIf="
          newPwd.dirty &&
          newPwd.hasError('minlength') &&
          !newPwd.hasError('required')
        "
        >A jelszó legalább 6 karakter legyen!</mat-error
      >
      <mat-error
        custom-error
        *ngIf="
          !newPwd.hasError('required') &&
          newPwd.hasError('oldAndNewPasswordMatch')
        "
        >Az új jelszó egyezik a régivel
      </mat-error>
      <mat-error
        custom-error
        *ngIf="newPwd.dirty && newPwd.hasError('confirmPasswordMatch')"
        >A jelszavak nem egyeznek</mat-error
      >
      <div custom-suffix *ngIf="newPwd.valid">
        <payee-icon [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"></payee-icon>
      </div>
    </app-custom-input-field>

    <app-custom-input-field appearance="outline">
      <span custom-label [class.custom-label-top]="!!newPwdConfirm.value"
        >Új jelszavad még egyszer</span
      >
      <input
        custom-input
        type="password"
        #confirm
        [formControl]="newPwdConfirm"
      />
      <div custom-suffix *ngIf="newPwdConfirm.valid">
        <payee-icon [name]="environment.CHECKMARK_GREEN_CIRCLE_ICON"></payee-icon>
      </div>
    </app-custom-input-field>
    <mat-error custom-error *ngIf="passwordForm.hasError('tooManyRequests')"
      >Túl sokszor próbálkoztál, próbáld újra késöbb
    </mat-error>
  </form>

  <button
    mat-button
    [disabled]="passwordForm.invalid"
    (click)="onClose()"
    class="accept"
  >
    Mentés
  </button>
  <button mat-button [mat-dialog-close]="false" class="decline">Mégsem</button>
</div>
