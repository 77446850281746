<div class="wrapper">
  <app-header title="Hitelezői fiók beállításai"> </app-header>
  <div class="flex menu">
    <button
      mat-flat-button
      disableRipple
      [class.selected]="tab === 'bank-account-edit'"
      (click)="tab = 'bank-account-edit'"
    >
      Számlaszám
    </button>
    <button
      mat-flat-button
      disableRipple
      [class.selected]="tab === 'user-options'"
      (click)="tab = 'user-options'"
    >
      Hangnem
    </button>
    <button
      mat-flat-button
      disableRipple
      [class.selected]="tab === 'api-integrations'"
      (click)="tab = 'api-integrations'"
    >
      Integrációk
    </button>
  </div>
  <div class="flex content column">
    <app-bank-account-edit
      *ngIf="tab === 'bank-account-edit'"
    ></app-bank-account-edit>
    <app-client-options *ngIf="tab === 'user-options'"></app-client-options>
    <app-api-integrations
      *ngIf="tab === 'api-integrations'"
    ></app-api-integrations>
  </div>
</div>
